/* ----------------------------------------------------------- */
/* == Responsive */
/* ----------------------------------------------------------- */

@media (max-width: 550px) {
	.logo__image {
		animation: none;
	}
	.member__list {
		flex-direction: column;
		margin-top: 4rem;
	}
}
